<template>
  <ECard
    v-if="hasData"
    :width="width"
    :title="metricName"
    class="chart-container kit-metric"
    :class="{ 'e-mx-auto e-overflow-hidden': isTable }"
  >
    <template #title>
      <KitMetricCardHeader
        :key="isDialogOpen"
        :title="metricName"
        :grafana-url="grafanaUrl"
        :is-dialog-open="isDialogOpen"
        :is-graph-view="isGraphView"
        :is-time-series="isTimeSeries"
        :selected-period="selectedPeriod"
        :show-period-selector="showPeriodSelector"
        @toggle-dialog="isDialogOpen = !isDialogOpen"
        @toggle-graph-view="isGraphView = !isGraphView"
        @select-period="$emit('select-period', $event)"
      />
    </template>

    <KitMetricChart
      :metric="metric"
      :metric-name="metricName"
      :metric-id="metricId"
      :table-view="!isGraphView"
      :is-loading="isLoading"
    />

    <v-dialog
      v-model="isDialogOpen"
      transition="none"
      :fullscreen="$vuetify.breakpoint.smAndDown"
      :content-class="dialogContentClass"
    >
      <v-card class="e-h-full e-relative">
        <v-card-title class="d-flex justify-space-between align-center">
          <KitMetricCardHeader
            :key="isDialogOpen"
            :title="metricName"
            :grafana-url="grafanaUrl"
            :is-dialog-open="isDialogOpen"
            :is-graph-view="isGraphView"
            :is-time-series="isTimeSeries"
            :selected-period="selectedPeriod"
            :show-period-selector="showPeriodSelector"
            @toggle-dialog="isDialogOpen = !isDialogOpen"
            @toggle-graph-view="isGraphView = !isGraphView"
            @select-period="$emit('select-period', $event)"
          />
        </v-card-title>
        <v-card-text class="e-h-full">
          <KitMetricChart
            :metric="metric"
            :metric-id="metricId"
            :metric-name="metricName"
            :table-view="!isGraphView"
            fit-container
          />
        </v-card-text>
      </v-card>
    </v-dialog>
  </ECard>
</template>

<script lang="ts">
import Vue, { PropType } from "vue"
import KitMetricChart from "./KitMetricChart.vue"
import KitMetricCardHeader from "./KitMetricCardHeader.vue"
import { KitMetricNames } from "@evercam/shared/constants/kits"
import {
  Kit,
  GrafanaMetricId,
  GrafanaMetricData,
  KitChartType,
  ExNvrMetric,
  EvercamMetricData,
  KitMetricId,
  KitMetricPeriod,
} from "@evercam/shared/types"

export default Vue.extend({
  name: "KitMetricCard",
  components: {
    KitMetricCardHeader,
    KitMetricChart,
  },
  props: {
    kit: {
      type: Object as PropType<Kit>,
      required: true,
    },
    metric: {
      type: [Object, Number] as PropType<
        GrafanaMetricData | ExNvrMetric | EvercamMetricData
      >,
      required: true,
    },
    metricId: {
      type: String as PropType<GrafanaMetricId | KitMetricId>,
      required: true,
    },
    selectedPeriod: {
      type: String,
      default: KitMetricPeriod.Last1Hour,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isDialogOpen: false,
      isGraphView: true,
    }
  },
  computed: {
    width(): string {
      if (this.$vuetify.breakpoint.lgAndUp) {
        return "80%"
      } else {
        return "90%"
      }
    },
    metricName(): string {
      return KitMetricNames[this.metricId]
    },
    isGrafanaMetric(): boolean {
      return Object.values(GrafanaMetricId).includes(this.metricId)
    },
    grafanaUrl(): string {
      if (!this.isGrafanaMetric) {
        return ""
      }

      return this.$config.public.grafanaDashboardUrl.replace(
        "{KIT_SERIAL}",
        this.kit.serial
      )
    },
    hasData(): boolean {
      if (!Number.isNaN(parseInt(this.metric.value))) {
        return true
      }
      if (this.metric?.stats?.length) {
        return true
      }

      if (!this.metric?.results) {
        return false
      }

      const resultEntries = Object.entries(this.metric.results)

      if (this.isTable) {
        const sizeData = resultEntries.find(([key]) => key === "A")?.[1]

        return !!sizeData?.frames?.[0]?.schema?.fields?.[1]?.labels
      }

      return resultEntries.some(([, value]) =>
        value?.frames?.some(
          (frame) =>
            frame?.schema?.fields?.length > 0 && frame?.data?.values?.length > 0
        )
      )
    },
    isTable(): boolean {
      return this.metric?.type === KitChartType.Table
    },
    isTimeSeries(): boolean {
      return [KitMetricId.BatteryStats, KitMetricId.PanelPowerStats].includes(
        this.metricId
      )
    },
    dialogContentClass(): string {
      const sizeClasses = this.$vuetify.breakpoint.mdAndUp
        ? "h-90 w-90"
        : "expanded-chart-dialog--mobile"

      return `expanded-chart-dialog ${sizeClasses}`
    },
    showPeriodSelector(): boolean {
      return ![GrafanaMetricId.LocalStorage].includes(this.metricId)
    },
  },
})
</script>

<style lang="scss">
.chart-container {
  padding: 0 !important;
  overflow: hidden;
}
.kit-metric {
  max-width: 100%;
  .e-card__title {
    margin-bottom: 0;
  }
}
.expanded-chart-dialog {
  overflow-y: hidden;
  &:not(.expanded-chart-dialog--mobile) .v-card > .v-card__text {
    height: calc(100% - 3rem) !important;
    padding: 3rem 2rem !important;
  }
}
</style>
