<template>
  <v-dialog v-model="dialog" max-width="550px" persistent @keydown.esc="close">
    <v-card>
      <v-card-title>
        <span>{{ formTitle }}</span>
      </v-card-title>
      <v-divider />
      <ValidationObserver v-slot="{ invalid, passes }" ref="routerForm">
        <v-card-text class="pt-5">
          <v-form>
            <ERow>
              <ECol cols="12" md="6" class="mt-n7 mb-5">
                <v-checkbox
                  v-if="isRouterReplacement || isNewRouter"
                  v-model="useExistingRouter"
                  class="resize-checkbox"
                  hide-details
                  @change="onToggleUseExistingRouter"
                >
                  <template #label>
                    <div class="caption">Use an existing router</div>
                  </template>
                </v-checkbox>
              </ECol>
              <ECol cols="12" md="6" class="mt-n2 py-0">
                <Autocomplete
                  v-if="useExistingRouter"
                  v-model="selectedRouter"
                  item-value="id"
                  item-text="serialNumber"
                  label="Serial Number"
                  attach
                  clearable
                  no-filter
                  :hide-details="false"
                  :cache-items="false"
                  :provider="getRouters"
                  :provider-params="routerParams"
                  @change="onSelectExistingRouter"
                />
              </ECol>
              <ECol v-if="showReAssignmentAlert" cols="12" class="mt-n2 py-0">
                <v-alert
                  prominent
                  text
                  dense
                  color="red"
                  type="warning"
                  class="py-1 w-100"
                  icon="fas fa-exclamation-triangle"
                >
                  <ERow align="center">
                    <ECol class="py-0">
                      <span class="subtitle-1">
                        The selected router:
                        <span class="e-font-bold text-no-wrap">{{
                          selectedRouter.serialNumber
                        }}</span>
                        is currently linked to
                        {{
                          selectedRouter.kitSerial
                            ? "the kit"
                            : "the camera(s)"
                        }}:
                        <EReadMore
                          class="e-font-bold"
                          :content="
                            selectedRouter.kitSerial
                              ? selectedRouter.kitSerial
                              : selectedRouterCamerasExids
                          "
                          :max-length="13"
                        />. Are you sure you want to reassign it to the current
                        kit:
                        <span class="e-font-bold text-no-wrap">{{
                          kitSummaryStore.kit.serial
                        }}</span>
                        ?
                      </span>
                    </ECol>
                  </ERow>
                </v-alert>
              </ECol>
              <ECol
                v-show="showReAssignmentAlert"
                cols="12"
                sm="12"
                md="12"
                lg="12"
                class="mt-n7"
              >
                <v-checkbox
                  v-model="confirmReassignment"
                  class="resize-checkbox"
                  label="I understand and confirm the reassignment"
                />
              </ECol>
              <ECol cols="12" sm="12" md="12" lg="12" class="py-0">
                <ValidationProvider
                  v-slot="{ errors }"
                  name="Serial Number"
                  :rules="{ required: !isEditRouter && !useExistingRouter }"
                >
                  <v-text-field
                    v-model="editedItem.serialNumber"
                    dense
                    outlined
                    :error-messages="errors"
                    label="Serial Number"
                    :disabled="isEditRouter || useExistingRouter"
                    placeholder="110xxxxxx"
                  />
                </ValidationProvider>
              </ECol>
              <ECol cols="12" sm="12" md="12" lg="12" class="py-0">
                <v-text-field
                  v-model="editedItem.netbirdUrl"
                  dense
                  outlined
                  disabled
                  label="HTTP URL"
                />
              </ECol>
              <ECol cols="12" sm="12" md="12" lg="12" class="py-0">
                <ValidationProvider
                  v-slot="{ errors }"
                  name="Router Type"
                  rules="required"
                >
                  <v-combobox
                    v-model="editedItem.routerType"
                    :items="routerTypes"
                    label="Router Type"
                    persistent-hint
                    dense
                    outlined
                    :error-messages="errors"
                  />
                </ValidationProvider>
              </ECol>
              <ECol cols="12" sm="12" md="6" lg="6" class="py-0">
                <ValidationProvider
                  v-slot="{ errors }"
                  name="VPN Server"
                  rules="required"
                >
                  <v-select
                    v-model="editedItem.vpnServer"
                    :items="VpnServers"
                    label="VPN Server"
                    dense
                    outlined
                    :error-messages="errors"
                    item-value="value"
                    item-text="name"
                  />
                </ValidationProvider>
              </ECol>
              <ECol cols="12" sm="12" md="6" lg="6" class="py-0">
                <ValidationProvider
                  v-slot="{ errors }"
                  name="Router HTTP Port"
                  rules="required"
                >
                  <v-text-field
                    v-model="editedItem.routerHttpPort"
                    :error-messages="errors"
                    dense
                    outlined
                    label="Router HTTP Port"
                  />
                </ValidationProvider>
              </ECol>
              <ECol cols="12" sm="12" md="6" lg="6" class="py-0">
                <v-text-field
                  v-model="editedItem.routerUserId"
                  dense
                  outlined
                  label="Router User ID"
                />
              </ECol>
              <ECol cols="12" sm="12" md="6" lg="6" class="py-0">
                <v-text-field
                  v-model="editedItem.routerPassword"
                  dense
                  outlined
                  label="Router Password"
                />
              </ECol>
              <ECol cols="12" sm="12" md="6" lg="6" class="py-0">
                <ValidationProvider
                  v-slot="{ errors }"
                  name="Power Type"
                  rules="required"
                >
                  <v-select
                    v-model="editedItem.powerType"
                    :items="powerTypes"
                    label="Power Type"
                    dense
                    outlined
                    :error-messages="errors"
                    item-value="value"
                    item-text="name"
                  />
                </ValidationProvider>
              </ECol>
              <ECol cols="12" sm="12" md="6" lg="6" class="py-0">
                <ValidationProvider
                  v-slot="{ errors }"
                  name="Power Schedule"
                  rules="required"
                >
                  <v-select
                    v-model="editedItem.powerSchedule"
                    :items="powerSchedules"
                    label="Power Schedule"
                    dense
                    outlined
                    :error-messages="errors"
                    item-value="value"
                    item-text="name"
                  />
                </ValidationProvider>
              </ECol>
              <ECol cols="12" sm="12" md="12" lg="12" class="py-0">
                <ERow no-gutters>
                  <ECol cols="12" sm="12" md="7" lg="7">
                    <Autocomplete
                      v-model="editedItem.sims"
                      item-value="id"
                      item-text="number"
                      label="SIMs"
                      multiple
                      chips
                      small-chips
                      deletable-chips
                      :list-items="sims"
                      resource="sims"
                      :search="simSearch"
                      @on-search-item="initSelectedSim"
                    />
                  </ECol>
                  <ECol cols="12" sm="12" md="5" lg="5" class="px-1 mt-3">
                    <span class="caption">Or</span>
                    <SimsDialog
                      for-form-component
                      @reload-sims="(sim) => (simSearch = sim)"
                    />
                  </ECol>
                </ERow>
              </ECol>
            </ERow>
          </v-form>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="grey" text @click="close"> Cancel </v-btn>
          <v-btn
            color="primary"
            text
            :disabled="
              invalid || (showReAssignmentAlert && !confirmReassignment)
            "
            :loading="isLoading"
            @click="passes(save)"
          >
            {{ submitActionTitle }}
          </v-btn>
        </v-card-actions>
      </ValidationObserver>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import {
  VpnServers,
  PowerSchedules,
  PowerTypes,
  RouterStatuses,
} from "@/components/constants.js"
import Autocomplete from "@evercam/shared/components/Autocomplete"
import Vue, { PropType } from "vue"
import { PowerType, Router, RouterStatus } from "@evercam/shared/types/router"
import { AdminApi } from "@evercam/shared/api/adminApi"
import SimsDialog from "@/components/sims/SimsDialog"
import { mapStores } from "pinia"
import { useKitSummaryStore } from "@/stores/kitSummary"
import { PowerSchedule } from "@evercam/shared/types"

export default Vue.extend({
  name: "RouterFormDialog",
  components: {
    Autocomplete,
    SimsDialog,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    editedRouter: {
      type: Object as PropType<Router>,
      default: () => ({} as Router),
    },
    routerTypes: {
      type: Array,
      required: true,
    },
    isRouterReplacement: {
      type: Boolean,
      default: false,
    },
  },
  data: () => {
    return {
      isLoading: false,
      VpnServers,
      confirmReassignment: false,
      routerStatus: RouterStatuses,
      powerSchedules: PowerSchedules,
      powerTypes: PowerTypes,
      editedItem: {
        serialNumber: null,
        vpnUserId: "",
        vpnPassword: "",
        routerType: "",
        routerUserId: "",
        routerPassword: "",
        routerHttpPort: 443,
        powerType: PowerType.Mains,
        powerSchedule: PowerSchedule.FullTime,
        vpnServer: "",
        netbirdUrl: "",
        status: RouterStatus.Active,
        sims: [],
      } as Router,
      defaultItem: {
        serialNumber: null,
        vpnUserId: "",
        vpnPassword: "",
        routerType: "",
        routerUserId: "",
        routerPassword: "",
        routerHttpPort: null,
        powerType: PowerType.Mains,
        powerSchedule: PowerSchedule.FullTime,
        vpnServer: "",
        netbirdUrl: "",
        status: RouterStatus.Active,
        sims: [],
      } as Router,
      sims: [],
      simSearch: "",
      selectedRouter: null,
      useExistingRouter: false,
    }
  },
  computed: {
    ...mapStores(useKitSummaryStore),
    selectedRouterCamerasExids() {
      if (!this.selectedRouter?.cameras) {
        return ""
      }

      return this.selectedRouter.cameras.map((c) => c.exid).join()
    },
    isRouterAssigned() {
      return this.selectedRouter?.kitSerial || this.selectedRouter?.cameras
    },
    getRouters() {
      return AdminApi.routers.getRouters
    },
    dialog: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit("input", value)
      },
    },
    netbirdUrl() {
      let region = this.kitSummaryStore.kit.countryRegion.replace("na", "us")

      return `https://192-168-8-1-${this.kitSummaryStore.kit.serial}.${region}-vpn.evercam.io:443`
    },
    isNewRouter() {
      return !this.editedRouter?.id
    },
    isEditRouter() {
      return !this.isNewRouter && !this.isRouterReplacement
    },
    formTitle() {
      if (this.isRouterReplacement) {
        return "Replace Router"
      }

      if (this.isNewRouter) {
        return "Add Router"
      }

      return "Edit Router"
    },
    submitActionTitle() {
      if (this.isRouterReplacement) {
        return "Replace"
      }

      if (this.isNewRouter) {
        return "Create"
      }

      return "Update"
    },
    showReAssignmentAlert() {
      return (
        this.useExistingRouter && this.selectedRouter && this.isRouterAssigned
      )
    },
  },
  watch: {
    dialog(value) {
      if (!value) {
        return
      }

      this.editedItem = {
        ...(this.isNewRouter ? this.defaultItem : this.editedRouter),
        netbirdUrl: this.netbirdUrl,
      }
      this.sims = this.editedItem?.sims || []
    },
  },
  methods: {
    onToggleUseExistingRouter(value) {
      if (value) {
        this.onSelectExistingRouter(this.selectedRouter)
      } else {
        this.clear()
      }
    },
    routerParams(val) {
      return {
        sort: "created_at|asc",
        limit: 50,
        page: 1,
        serialNumber: val,
      }
    },
    async save() {
      this.isLoading = true
      if (this.useExistingRouter) {
        // Link kit to an existing router
        await this.kitSummaryStore.updateRouter({
          ...this.editedItem,
          kitId: this.kitSummaryStore.kit.id,
        })
      } else if (this.isEditRouter) {
        // Update existing router
        await this.kitSummaryStore.updateRouter({
          ...this.editedItem,
        })
      } else {
        // Create a new router
        await this.kitSummaryStore.createRouter(this.editedItem)
      }
      this.isLoading = false

      this.close()
    },
    onSelectExistingRouter(router: Router) {
      if (!router) {
        return
      }
      this.editedItem = { ...router, netbirdUrl: this.netbirdUrl }
      this.sims = this.editedItem.sims
    },
    close() {
      this.dialog = false
      this.$emit("update:is-router-replacement", false)
      this.$setTimeout(() => {
        this.clear()
      }, 500)
    },
    clear() {
      this.$refs.routerForm.reset()
      this.selectedRouter = null
      this.useExistingRouter = false
      this.editedItem = { ...this.defaultItem, netbirdUrl: this.netbirdUrl }
    },
    initSelectedSim(items) {
      if (!this.simSearch) {
        return
      }
      this.editedItem.sims = [
        ...(this.editedItem.sims || []),
        items.find((value) => value.number === this.simSearch),
      ]
      this.sims = this.editedItem.sims
    },
  },
})
</script>
