<template>
  <ECard
    title="NVR"
    :image="`${require('~/assets/img/sbc/rpi4.gif')}`"
    blur-background
    :image-translation="20"
    :image-size="70"
    :image-padding="5"
    v-bind="$attrs"
  >
    <div v-if="hasNvr">
      <!-- SERIAL NUMBER -->
      <SummaryElement
        v-model="serial"
        :has-default-save-callback="false"
        is-id
        is-copyable
        @save="saveNvr"
      >
        <template #append>
          <OpenInNewWindowButton
            v-if="nervesDeviceUrl"
            hint="Open in Nerves"
            svg-icon="nerves"
            :target-link="nervesDeviceUrl"
            :icon-props="{
              width: '19',
              height: '11',
            }"
          />
        </template>
      </SummaryElement>

      <!-- VERSION -->
      <SummaryElement
        label="Version"
        :value="kitSummaryStore.exNvrSystemStatus?.version || '-'"
        read-only
      />

      <!-- DEVICE TYPE -->
      <SummaryElement
        v-model="deviceType"
        :has-default-save-callback="false"
        @save="saveNvr"
      />

      <!-- NVR MODEL -->
      <SummaryElement read-only label="NVR type">
        {{
          kitSummaryStore.nvr.model
            ? toTitleCase(kitSummaryStore.nvr.model)
            : "-"
        }}
      </SummaryElement>

      <!-- HTTP URL -->
      <SummaryElement
        :value="kitSummaryStore.nvr.httpUrl"
        label="HTTP URL"
        read-only
        is-copyable
      >
        <template #value-text>
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <ETruncatedDiv :width="200" v-bind="attrs" v-on="on">
                <a target="_blank" :href="kitSummaryStore.nvr.httpUrl">
                  <span>{{ kitSummaryStore.nvr.httpUrl }}</span>
                </a>
              </ETruncatedDiv>
            </template>
            <span>{{ kitSummaryStore.nvr.httpUrl }}</span>
          </v-tooltip>
        </template>
      </SummaryElement>

      <!-- RTSP URL -->
      <SummaryElement
        v-model="rtspUrl"
        :has-default-save-callback="false"
        @save="saveNvr"
      />

      <!-- USERNAME -->
      <SummaryElement
        v-model="username"
        :has-default-save-callback="false"
        is-copyable
        @save="saveNvr"
      />

      <!-- PÄSSWORD -->
      <SummaryElement
        v-model="password"
        :has-default-save-callback="false"
        is-password
        is-copyable
        @save="saveNvr"
      />

      <div class="kit-nvr__metrics-container e-flex e-flex-wrap">
        <div v-if="exNvrMetrics">
          <div class="kit-nvr__metrics">
            <KitMetricGaugeChart
              v-for="(metric, metricName) in exNvrMetrics"
              :key="metricName"
              :metric="metric"
              :title="metricName"
            />
          </div>
        </div>

        <div v-else-if="grafanaMetrics" class="kit-nvr__metrics--grafana">
          <div class="kit-nvr__metrics">
            <KitMetricGaugeChart
              v-for="(metric, metricName) in grafanaMetrics"
              :key="metricName"
              :metric="metric"
              :title="metricName"
            />
          </div>
        </div>
      </div>
    </div>
    <div v-else class="subtitle-2">
      <!-- ADD NVR -->
      <v-btn
        color="primary"
        small
        plain
        class="mr-1"
        @click="addNvrDialog = true"
      >
        <v-icon class="mr-2">fa-plus </v-icon> Add NVR
      </v-btn>

      <AddNvrDialog
        v-model="addNvrDialog"
        @created="kitSummaryStore.refreshKit"
      />
    </div>
  </ECard>
</template>

<script lang="ts">
import Vue, { PropType } from "vue"
import SummaryElement from "@/components/cameraTabs/summary/SummaryElement"
import KitMetricGaugeChart from "@/components/kits/KitMetricGaugeChart"
import OpenInNewWindowButton from "@/components/OpenInNewWindowButton"
import AddNvrDialog from "@/components/kits/AddNvrDialog"
import { mapStores } from "pinia"
import { useKitSummaryStore } from "@/stores/kitSummary"
import { Nvr } from "@evercam/shared/types"
import {
  ExNvrMetric,
  ExNvrMetricId,
  GrafanaMetricId,
  GrafanaMetricData,
} from "@evercam/shared/types"
import { NvrDeviceTypes } from "@/components/constants"

export default Vue.extend({
  components: {
    SummaryElement,
    KitMetricGaugeChart,
    AddNvrDialog,
    OpenInNewWindowButton,
  },
  props: {
    grafanaMetrics: {
      type: Object as PropType<Record<
        GrafanaMetricId,
        GrafanaMetricData
      > | null>,
      default: null,
    },
    exNvrMetrics: {
      type: Object as PropType<Record<ExNvrMetricId, ExNvrMetric> | null>,
      default: null,
    },
  },
  data() {
    return {
      addNvrDialog: false,
      nvr: {} as Nvr,
      serial: {
        key: "serial",
        label: "Serial",
        value: "",
        type: "TEXT_FIELD",
      },
      deviceType: {
        key: "deviceType",
        label: "SBC type",
        value: "",
        type: "SINGLE_SELECT",
        dataset: NvrDeviceTypes,
      },
      username: {
        key: "username",
        label: "Username",
        value: "",
        type: "TEXT_FIELD",
      },
      password: {
        key: "password",
        label: "Password",
        value: "",
        type: "TEXT_FIELD",
      },
      rtspUrl: {
        key: "rtspUrl",
        label: "RTSP URL",
        value: "",
        type: "TEXT_FIELD",
      },
    }
  },
  computed: {
    ...mapStores(useKitSummaryStore),
    hasNvr(): boolean {
      return !!this.kitSummaryStore.nvr?.id
    },
    nervesDeviceUrl() {
      return this.kitSummaryStore.nvr?.nerves &&
        this.kitSummaryStore.nvr?.serial
        ? `${this.$config.public.nervesDashboardUrl}/devices/${this.kitSummaryStore.nvr?.serial}`
        : ""
    },
  },
  watch: {
    "kitSummaryStore.nvr": {
      async handler() {
        this.bindNvr()
      },
    },
  },
  mounted() {
    this.bindNvr()
  },
  methods: {
    bindNvr() {
      this.rtspUrl.value = this.kitSummaryStore.nvr.rtspUrl
      this.password.value = this.kitSummaryStore.nvr.password
      this.username.value = this.kitSummaryStore.nvr.username
      this.deviceType.value = this.kitSummaryStore.nvr.deviceType
      this.serial.value = this.kitSummaryStore.nvr.serial
    },
    async saveNvr() {
      let params = {
        rtspUrl: this.rtspUrl.value,
        password: this.password.value,
        username: this.username.value,
        deviceType: this.deviceType.value,
        serial: this.serial.value,
      }
      this.kitSummaryStore.updateNvr(params)
    },
  },
})
</script>

<style lang="scss">
.kit-nvr__metrics {
  display: grid;
  justify-content: start;
  grid-template-columns: repeat(auto-fill, 186px);
  .summary-element {
    min-height: 18px !important;
    max-height: 18px;
  }
}
.kit-nvr__metrics--grafana {
  flex-grow: 0.25;
}
</style>
