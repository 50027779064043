<template>
  <div
    v-if="chartOptions"
    class="kit-metric__graph-chart e-flex e-w-full e-h-full position-relative e-flex-wrap e-justify-center e-items-center"
    :class="isLoading ? 'e-bg-gray-50' : ''"
  >
    <EvercamLoadingAnimation v-if="isLoading" size="48" />
    <highcharts v-else :options="chartOptions"></highcharts>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from "vue"
import VueHighcharts from "@evercam/shared/components/Highcharts"
import Highcharts from "highcharts"
import {
  EvercamStats,
  GrafanaMetricData,
  GrafanaQueryResult,
  HighchartsStackingBehavior,
} from "@evercam/shared/types"
import EvercamLoadingAnimation from "@evercam/shared/components/EvercamLoadingAnimation.vue"

export default Vue.extend({
  name: "KitMetricGraphChart",
  components: {
    EvercamLoadingAnimation,
    highcharts: VueHighcharts,
  },
  props: {
    metric: {
      type: Object as PropType<GrafanaMetricData | { stats: EvercamStats }>,
      required: true,
    },
    chartType: {
      type: String,
      default: "area",
    },
    metricName: {
      type: String,
      default: "",
    },
    convertToBytes: {
      type: Boolean,
      default: false,
    },
    convertToBitsPerSecond: {
      type: Boolean,
      default: false,
    },
    percentage: {
      type: Boolean,
      default: false,
    },
    temperature: {
      type: Boolean,
      default: false,
    },
    defaultTooltip: {
      type: Boolean,
      default: false,
    },
    stacking: {
      type: [String, Boolean] as PropType<HighchartsStackingBehavior | false>,
      default: HighchartsStackingBehavior.Normal,
    },
    yAxisConfig: {
      type: Array as PropType<Array<{ title: string; targets: string[] }>>,
      default: () => [],
    },
    width: {
      type: Number,
      default: 0,
    },
    height: {
      type: Number,
      default: 0,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    labelFormatter():
      | Highcharts.AxisLabelsFormatterCallbackFunction
      | undefined {
      const that = this
      if (this.convertToBytes) {
        return function () {
          return that.$units.formatBytes(this.value)
        }
      } else if (this.convertToBitsPerSecond) {
        return function () {
          return that.$units.formatBitsPerSecond(this.value)
        }
      } else if (this.percentage) {
        return function () {
          return that.$units.formatPercentage(this.value)
        }
      } else if (this.temperature) {
        return function () {
          return that.$units.formatTemperature(this.value)
        }
      } else {
        return function () {
          const axisLabel = (
            this.axis?.options?.title?.text || ""
          ).toLowerCase()
          if (axisLabel.includes("voltage")) {
            return that.$units.formatVoltage(this.value)
          } else if (axisLabel.includes("power")) {
            return that.$units.formatPower(this.value)
          } else if (axisLabel.includes("current")) {
            return that.$units.formatCurrent(this.value)
          }

          return this.value
        }
      }
    },
    tooltipFormatter():
      | Highcharts.TooltipFormatterCallbackFunction
      | undefined {
      if (this.defaultTooltip) {
        return undefined
      }

      if (this.convertToBytes) {
        return this.createTooltipFormatter(this.$units.formatBytes)
      } else if (this.convertToBitsPerSecond) {
        return this.createTooltipFormatter(this.$units.formatBitsPerSecond)
      } else if (this.percentage) {
        return this.createTooltipFormatter(this.$units.formatPercentage)
      } else if (this.temperature) {
        return this.createTooltipFormatter(this.$units.formatTemperature)
      } else {
        return this.createTooltipFormatter(this.defaultTooltipFormatter)
      }
    },
    chartOptions(): Highcharts.Options | null {
      let series: Highcharts.Series
      if (this.metric.results) {
        series = this.grafanaResultsToHighchartsSeries(this.metric.results)
      } else if (this.metric.stats) {
        series = this.evercamStatsToHighchartsSeries(this.metric.stats)
      } else {
        return null
      }

      let yAxis
      if (this.yAxisConfig?.length > 0 && Array.isArray(series)) {
        series = this.assignSeriesToConfiguredAxes(series)
        yAxis = this.yAxisConfig.map((config, index) => {
          return {
            tickAmount: 6,
            title: {
              text: config.title || "",
            },
            labels: {
              formatter: this.labelFormatter,
            },
            opposite: index > 0,
          }
        })
      } else {
        yAxis = {
          tickAmount: 6,
          title: {
            enabled: false,
          },
          labels: {
            formatter: this.labelFormatter,
          },
        }
      }

      return {
        series,
        yAxis,
        chart: {
          height: this.height ? this.height : "250px",
          width: this.width ? this.width : undefined,
          type: this.chartType,
          animation: false,
          style: {
            fontSize: "14px",
          },
        },
        title: false,
        xAxis: {
          tickInterval: 3600_000 / 6,
          type: "datetime",
          title: {
            enabled: false,
          },
        },
        tooltip: {
          shared: true,
          xDateFormat: "%Y-%m-%d %H:%M:%S",
          formatter: this.tooltipFormatter,
        },
        credits: {
          enabled: false,
        },
        plotOptions: {
          series: {
            pointStart: 2012,
            animation: false,
          },
          area: {
            stacking: this.stacking,
            lineColor: "#666666",
            lineWidth: 0.2,
            marker: {
              radius: 1,
              lineWidth: 0.2,
              lineColor: "#666666",
            },
          },
        },
        legend: {
          itemStyle: {
            fontSize: "12px",
          },
          symbolWidth: 16,
          symbolHeight: 16,
          itemDistance: 10,
        },
      }
    },
  },

  methods: {
    createTooltipFormatter(
      formatter: (value: number, seriesName: string) => string
    ): Highcharts.TooltipFormatterCallbackFunction {
      return function () {
        const points = this.points || []
        const timeStr = Highcharts.dateFormat("%Y-%m-%d %H:%M:%S", this.x)

        return `<b>${timeStr}</b><br/>${points
          .map(
            (point) =>
              `${point.series.name}: ${formatter(point.y, point.series.name)}`
          )
          .join("<br/>")}`
      }
    },
    defaultTooltipFormatter(value: number, seriesName: string = ""): string {
      if (seriesName.toLowerCase().includes("voltage")) {
        return `${value}V`
      } else if (seriesName.toLowerCase().includes("power")) {
        return `${value}W`
      } else if (seriesName.toLowerCase().includes("current")) {
        return `${value}mA`
      }

      return `${value}`
    },
    grafanaResultsToHighchartsSeries(
      grafanaResults: GrafanaQueryResult
    ): Highcharts.Series {
      return Object.entries(grafanaResults).reduce(
        (acc, [_resultKey, result]) => {
          const frameSeries = result.frames.reduce((frameAcc, frame) => {
            const fields = frame.schema.fields
            const dataValues = frame.data.values
            const timeField = fields.find((f) => f.type === "time")
            const valueField = fields.find((f) => f.type === "number")

            if (
              timeField &&
              valueField &&
              !["node_memory_MemTotal_bytes"].includes(valueField.name)
            ) {
              const [times, values] = dataValues
              const meanValue =
                values.reduce((sum, val) => sum + val, 0) / values.length

              frameAcc.push({
                name: this.getFieldName(valueField),
                data: times.map((time, index) => [time, values[index]]),
                zIndex: -meanValue,
              })
            }

            return frameAcc
          }, [])

          return [...acc, ...frameSeries]
        },
        []
      ) as unknown as Highcharts.Series
    },
    assignSeriesToConfiguredAxes(series) {
      if (!Array.isArray(series)) return

      series.forEach((s) => {
        let axisIndex = 0

        this.yAxisConfig.forEach((config, index) => {
          if (config.targets && config.targets.includes(s.name)) {
            axisIndex = index
          }
        })

        s.yAxis = axisIndex
      })

      return this.reorderZIndexByAxis(series)
    },
    evercamStatsToHighchartsSeries(data: EvercamStats[]): Highcharts.Series {
      return Object.values(
        data.reduce((acc, item) => {
          const { timestamp, ...fields } = item

          return Object.keys(fields).reduce((fieldAcc, field, index) => {
            if (!fieldAcc[field]) {
              fieldAcc[field] = {
                name: field,
                data: [],
                zIndex: index,
              }
            }
            fieldAcc[field].data.push([
              new Date(timestamp).getTime(),
              fields[field],
            ])

            return fieldAcc
          }, acc)
        }, {})
      ) as unknown as Highcharts.Series
    },
    reorderZIndexByAxis(series) {
      if (!Array.isArray(series)) return series

      const axisGroups = {}

      series.forEach((s) => {
        const axisIndex = s.yAxis || 0
        axisGroups[axisIndex] = axisGroups[axisIndex] || []
        axisGroups[axisIndex].push(s)
      })
      let zIndex = 0
      Object.keys(axisGroups)
        .sort()
        .forEach((axisKey) => {
          axisGroups[axisKey].forEach((s) => {
            s.zIndex = zIndex++
          })
        })

      return series
    },
    getFieldName(field) {
      if (!field) return "Value"
      if (field.labels?.mountpoint) {
        return `${field.labels.mountpoint} (${field.labels.fstype})`
      }
      if (field.labels?.device) return field.labels.device
      if (field.config?.displayNameFromDS) return field.config.displayNameFromDS
      if (field.name) return field.name

      return "Value"
    },
  },
})
</script>

<style>
.kit-metric__graph-chart {
  min-height: 250px;
}
</style>
