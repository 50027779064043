<template>
  <div
    v-resize-observer="onResize"
    class="kit-metric-chart-content e-h-full"
    :class="containerClasses"
    :style="containerStyles"
  >
    <KitMetricTable
      v-if="isTable || tableView"
      :data="metric"
      :metric-id="metricId"
      :height="fitContainer ? height - 42 : undefined"
      v-bind="metric.chartProps"
    />
    <KitMetricGraphChart
      v-else-if="isGraphChart"
      :metric="metric"
      :metric-name="metricName"
      :convert-to-bytes="convertToBytes"
      :convert-to-bits-per-second="convertToBitsPerSecond"
      :stacking="stackingBehavior"
      :percentage="isPercentage"
      :temperature="isTemperature"
      :default-toolip="useDefaultTooltip"
      :width="width"
      :height="height"
      :is-loading="isLoading"
      v-bind="metric.chartProps"
    />
    <KitMetricGaugeChart
      v-else-if="isGaugeChart"
      :metric="metric"
      v-bind="metric.chartProps"
    />
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from "vue"
import KitMetricTable from "@/components/kits/KitMetricTable.vue"
import KitMetricGraphChart from "@/components/kits/KitMetricGraphChart.vue"
import KitMetricGaugeChart from "@/components/kits/KitMetricGaugeChart.vue"
import {
  GrafanaMetricData,
  ExNvrMetric,
  EvercamMetricData,
  KitChartType,
  GrafanaMetricId,
  HighchartsStackingBehavior,
} from "@evercam/shared/types"

export default Vue.extend({
  name: "KitMetricChartContent",
  components: {
    KitMetricGaugeChart,
    KitMetricGraphChart,
    KitMetricTable,
  },
  props: {
    metric: {
      type: [Object, Number] as PropType<
        GrafanaMetricData | ExNvrMetric | EvercamMetricData
      >,
      required: true,
    },
    metricId: {
      type: [String, Number] as PropType<GrafanaMetricId>,
      required: true,
    },
    metricName: {
      type: String,
      required: true,
    },
    fitContainer: {
      type: Boolean,
      default: false,
    },
    tableView: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      width: 0,
      height: 0,
    }
  },
  computed: {
    containerStyles(): Record<string, unknown> {
      if (this.fitContainer && this.width && this.height) {
        return {
          width: `${this.width}px`,
          height: `${this.height}px`,
        }
      } else {
        return {}
      }
    },
    containerClasses(): Record<string, boolean> {
      return {
        ["e-mt-2"]: this.isGraphChart && !(this.tableView || this.isLoading),
      }
    },
    isGraphChart(): boolean {
      return [KitChartType.Graph, KitChartType.TimeSeries].includes(
        this.metric?.type
      )
    },
    isGaugeChart(): boolean {
      return this.metric?.type === KitChartType.Gauge
    },
    isTable(): boolean {
      return this.metric?.type === KitChartType.Table
    },
    isPercentage(): boolean {
      return [
        GrafanaMetricId.SbcCpuStats,
        GrafanaMetricId.StorageSpaceUsed,
      ].includes(this.metricId)
    },

    isTemperature(): boolean {
      return [
        GrafanaMetricId.SbcTemperatureStats,
        GrafanaMetricId.RouterTemperature,
      ].includes(this.metricId)
    },
    convertToBytes(): boolean {
      return [GrafanaMetricId.SbcRamStats].includes(this.metricId)
    },
    convertToBitsPerSecond(): boolean {
      return [GrafanaMetricId.SbcNetworkStats].includes(this.metricId)
    },
    stackingBehavior(): string | false {
      if ([GrafanaMetricId.SbcRamStats].includes(this.metricId)) {
        return HighchartsStackingBehavior.Normal
      } else if ([GrafanaMetricId.SbcCpuStats].includes(this.metricId)) {
        return HighchartsStackingBehavior.Percent
      } else {
        return false
      }
    },
    useDefaultTooltip(): boolean {
      return [GrafanaMetricId.SbcCpuStats].includes(this.metricId)
    },
  },
  methods: {
    onResize({ contentRect }) {
      this.width = contentRect.width
      this.height = contentRect.height
    },
  },
})
</script>

<style lang="scss" scoped>
.kit-metric-chart-content {
  width: 100%;
}
</style>
