<template>
  <div class="kit-table-wrapper e-h-full">
    <v-data-table
      :items="items"
      :headers="headers"
      dense
      :height="height"
      :fixed-header="!!height"
      class="kit-table"
      :sort-by="sortBy"
      :sort-desc="sortDesc"
    >
      <template #item.usedPercent="{ item }">
        <ScoreBar
          class="kit-metric__gauge-chart"
          :score="item.usedPercent"
          :max-score="100"
          invert-colors
          suffix="%"
        />
      </template>
      <template #item.timestamp="{ item }">
        {{ $moment(item.timestamp).format("YYYY-MM-DD HH:mm:ss") }}
      </template>
      <template #item.voltage="{ item }">
        {{ formatFloat(item.voltage) }}V
      </template>
      <template #item.current="{ item }">
        {{ formatFloat(item.current) }}mA
      </template>
      <template #item.panelVoltage="{ item }">
        {{ formatFloat(item.panelVoltage) }}V
      </template>
      <template #item.panelPower="{ item }">
        {{ formatFloat(item.panelPower) }}W
      </template>
    </v-data-table>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from "vue"
import ScoreBar from "@evercam/shared/components/ScoreBar.vue"
import {
  GrafanaMetricData,
  GrafanaMetricId,
  KitMetricId,
} from "@evercam/shared/types"
import { toTitleCase } from "@evercam/shared/utils"

export default Vue.extend({
  name: "KitMetricTable",
  components: {
    ScoreBar,
  },
  props: {
    data: {
      type: Object as PropType<
        GrafanaMetricData | Array<Record<string, unknown>>
      >,
      required: true,
    },
    metricId: {
      type: [String, Number] as PropType<GrafanaMetricId | KitMetricId>,
      required: true,
    },
    height: {
      type: [Number, undefined],
      default: undefined,
    },
  },
  computed: {
    items(): Array<Record<string, unknown>> {
      if (this.metricId === GrafanaMetricId.LocalStorage) {
        return this.processFilesystemData(this.data as GrafanaMetricData)
      } else {
        return this.defaultItems
      }
    },
    defaultItems(): Record<string, unknown>[] {
      let items = this.data?.stats || this.data
      if (!Array.isArray(items)) {
        return []
      }

      return items
    },
    headers() {
      if (this.metricId === GrafanaMetricId.LocalStorage) {
        return this.localStorageHeaders
      } else {
        return this.defaultHeaders
      }
    },
    defaultHeaders(): Array<Record<string, unknown>> {
      if (!this.items?.length) {
        return []
      }

      return Object.keys(this.items[0]).map((key) => ({
        text: toTitleCase(key),
        value: key,
        sortable: true,
      }))
    },
    hasTimestampColumn(): boolean {
      return this.headers.some((header) => header.value === "timestamp")
    },
    sortBy(): string {
      return this.hasTimestampColumn ? "timestamp" : undefined
    },
    sortDesc(): boolean {
      return this.hasTimestampColumn ? true : undefined
    },
    localStorageHeaders(): Array<Record<string, unknown>> {
      return [
        {
          text: "Mountpoint",
          value: "mountpoint",
          sortable: true,
        },
        {
          text: "Device",
          value: "device",
          sortable: true,
        },
        {
          text: "Filesystem Type",
          value: "fstype",
          sortable: true,
        },
        {
          text: "Total Space",
          value: "totalSpace",
          sortable: true,
        },
        {
          text: "Used Space",
          value: "usedPercent",
          sortable: true,
        },
        {
          text: "Read Only",
          value: "readonly",
          sortable: true,
        },
        {
          text: "Network Region 1",
          value: "region1",
          sortable: true,
        },
        {
          text: "Network Region 2",
          value: "region2",
          sortable: true,
        },
        {
          text: "Network Region 3",
          value: "region3",
          sortable: true,
        },
      ]
    },
  },
  methods: {
    processFilesystemData(data: GrafanaMetricData): Record<string, unknown>[] {
      if (!data?.results) return []

      const filesystems = []
      const resultEntries = Object.entries(data.results)

      const sizeData = resultEntries.find(([key]) => key === "A")?.[1]
      const usageData = resultEntries.find(([key]) => key === "B")?.[1]
      const readonlyData = resultEntries.find(([key]) => key === "C")?.[1]

      if (!sizeData) return []

      sizeData.frames.forEach((frame, index) => {
        const labels = frame.schema.fields[1].labels
        if (["/boot", "/run", "/tmp"].includes(labels.mountpoint)) {
          return
        }

        const totalSpace = frame.data.values[1][0]
        const usedPercent = usageData?.frames[index]?.data.values[1][0] || 0
        const readonly = readonlyData?.frames[index]?.data.values[1][0] || 0

        filesystems.push({
          mountpoint: labels.mountpoint,
          device: labels.device,
          fstype: labels.fstype,
          totalSpace: this.$units.formatBytes(totalSpace),
          usedPercent: parseFloat(usedPercent.toFixed(1)),
          readonly: readonly === 1 ? "YES" : "NO",
          region1: labels.network_region || "emea",
          region2: labels.network_region || "emea",
          region3: labels.network_region || "emea",
        })
      })

      return filesystems
    },
    formatFloat(value: number) {
      let strValue = `${value}`

      if (strValue.includes(".")) {
        return value.toFixed(2)
      } else {
        return value
      }
    },
  },
})
</script>

<style lang="scss">
.kit-table-wrapper {
  .v-data-table--dense > .v-data-table__wrapper > table {
    td,
    th {
      height: 20px !important;
      font-size: 12px !important;
      color: rgb(75, 85, 99) !important;
      border: none !important;
    }
    & > thead {
      tr {
        background-color: rgba(226, 228, 229, 0.71) !important;
      }
      &:after {
        content: "-";
        display: block;
        line-height: 6px;
        color: transparent;
      }
      th:first-of-type {
        overflow: hidden;
      }
      th:last-of-type {
        overflow: hidden;
      }
    }
    tr:nth-child(odd) {
      background-color: rgba(240, 242, 243, 0.71);
      overflow: hidden;
    }

    & > tbody {
      tr:first-child {
        td:first-child {
          border-top-left-radius: 0.5em;
        }
        td:last-child {
          border-top-right-radius: 0.5em;
        }
      }
      tr:last-child {
        td:first-child {
          border-bottom-left-radius: 0.5em;
        }
        td:last-child {
          border-bottom-right-radius: 0.5em;
        }
      }
    }
  }
}
</style>
