<template>
  <div>
    <KitSummary :kit-id="kitId" />

    <portal to="breadcrumbs-right">
      <KitDeleteDialog v-if="canDeleteKits" />
    </portal>
  </div>
</template>

<script>
import KitSummary from "@/components/kits/KitSummary"
import { useKitSummaryStore } from "@/stores/kitSummary"
import { useBreadcrumbStore } from "@/stores/breadcrumb"
import KitDeleteDialog from "@/components/kits/KitDeleteDialog.vue"
import { PERMISSIONS } from "@/constants/permissions"

export default {
  components: {
    KitDeleteDialog,
    KitSummary,
  },
  async asyncData({ params, from }) {
    const kitSummaryStore = useKitSummaryStore()
    await kitSummaryStore.loadKit(params.id)

    let kitsPageFullPath = kitSummaryStore.previousKitsPageFullPath

    if (from?.path !== "/kits" || !kitsPageFullPath) {
      kitsPageFullPath = "/kits"
    }

    const breadcrumbStore = useBreadcrumbStore()
    breadcrumbStore.breadcrumbs = [
      {
        name: "Home",
        href: "/",
        icon: "fa-house",
      },
      {
        name: "Kits",
        href: kitsPageFullPath,
        icon: "fa-box",
      },
      {
        name: `${kitSummaryStore.kit?.name || ""} - (${
          kitSummaryStore.kit?.serial || ""
        })`,
      },
    ]

    return {
      kitId: params.id,
    }
  },
  head() {
    const KitSummaryStore = useKitSummaryStore()

    return {
      title: `${KitSummaryStore?.kit.name} - (${KitSummaryStore.kit?.serial})`,
    }
  },
  computed: {
    canDeleteKits() {
      return this.$permissions.user.can(PERMISSIONS.KITS.DELETE)
    },
  },
}
</script>
