<template>
  <div
    class="d-flex justify-center align-center kit-metric__header e-py-2 e-border-gray-200"
  >
    <span class="e-text-sm pl-1">{{ title }}</span>
    <div class="e-absolute e-right-4 d-flex align-center">
      <!-- Period Selection Dropdown -->
      <v-menu
        v-if="showPeriodSelector"
        offset-y
        bottom
        left
        close-on-content-click
      >
        <template #activator="{ on, attrs }">
          <v-btn
            small
            text
            color="primary"
            class="period-selector px-2"
            v-bind="attrs"
            v-on="on"
          >
            <span class="text-caption mr-1">{{
              formatPeriod(selectedPeriod)
            }}</span>
            <v-icon x-small>fas fa-chevron-down</v-icon>
          </v-btn>
        </template>
        <v-list dense>
          <v-list-item
            v-for="(period, index) in periodOptions"
            :key="index"
            :class="{ 'v-list-item--active': selectedPeriod === period.value }"
            @click="$emit('select-period', period.value)"
          >
            <v-list-item-title>{{ period.text }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>

      <v-tooltip bottom>
        <template #activator="{ on, attrs }">
          <v-btn
            v-if="isTimeSeries"
            icon
            color="primary"
            v-bind="attrs"
            v-on="on"
            @click="$emit('toggle-graph-view')"
          >
            <v-icon small>{{
              isGraphView ? "fas fa-table" : "fas fa-chart-simple"
            }}</v-icon>
          </v-btn>
        </template>
        {{ isGraphView ? "Switch to table view" : "Switch to graph view" }}
      </v-tooltip>

      <OpenInNewWindowButton
        v-if="grafanaUrl"
        :target-link="grafanaUrl"
        hint="Open in Grafana"
        class="ml-1"
      />

      <v-tooltip bottom>
        <template #activator="{ on, attrs }">
          <v-btn
            v-if="!isDialogOpen"
            icon
            color="primary"
            v-bind="attrs"
            v-on="on"
            @click="$emit('toggle-dialog')"
          >
            <v-icon small>fas fa-expand-arrows-alt</v-icon>
          </v-btn>
        </template>
        Expand
      </v-tooltip>

      <v-btn
        v-if="isDialogOpen"
        icon
        class="large-icon"
        @click="$emit('toggle-dialog')"
      >
        <v-icon color="primary">fas fa-times</v-icon>
      </v-btn>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from "vue"
import OpenInNewWindowButton from "@/components/OpenInNewWindowButton.vue"
import { KitMetricPeriod } from "@evercam/api"

export default Vue.extend({
  name: "KitMetricHeader",
  components: {
    OpenInNewWindowButton,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    isDialogOpen: {
      type: Boolean,
      default: false,
    },
    isTimeSeries: {
      type: Boolean,
      default: false,
    },
    isGraphView: {
      type: Boolean,
      default: true,
    },
    grafanaUrl: {
      type: String,
      default: "",
    },
    selectedPeriod: {
      type: String,
      default: KitMetricPeriod.Last1Hour,
    },
    showPeriodSelector: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    periodOptions() {
      return [
        { text: "Last 15 Minutes", value: KitMetricPeriod.Last15Minutes },
        { text: "Last 1 Hour", value: KitMetricPeriod.Last1Hour },
        { text: "Last 24 Hours", value: KitMetricPeriod.Last24Hours },
        { text: "Last 7 Days", value: KitMetricPeriod.Last7Days },
        { text: "Last 30 Days", value: KitMetricPeriod.Last30Days },
        { text: "Last 90 Days", value: KitMetricPeriod.Last90Days },
      ]
    },
  },
  methods: {
    formatPeriod(periodValue: string): string {
      const option = this.periodOptions.find((p) => p.value === periodValue)

      return option ? option.text : "Select period"
    },
  },
})
</script>

<style>
.kit-metric__header {
  border-bottom-style: solid;
  border-bottom-width: 1px;
}
.period-selector {
  min-width: 100px;
}
</style>
