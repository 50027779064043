<template>
  <ECard
    title="Router"
    :image="imageUrl"
    blur-background
    :image-translation="20"
    :image-size="70"
    :image-padding="10"
    v-bind="$attrs"
  >
    <template #title>
      Router
      <span v-if="hasRouter">
        <v-tooltip top>
          <template #activator="{ on, attrs }">
            <v-btn
              small
              icon
              v-bind="attrs"
              v-on="on"
              @click="openRouterFormDialog"
            >
              <v-icon>fas fa-pen</v-icon>
            </v-btn>
          </template>
          <span>Edit Router</span>
        </v-tooltip>
        <v-tooltip top>
          <template #activator="{ on, attrs }">
            <v-btn
              small
              icon
              v-bind="attrs"
              v-on="on"
              @click="openReplaceRouterFormDialog"
            >
              <v-icon>fas fa-rotate</v-icon>
            </v-btn>
          </template>
          <span>Replace Router</span>
        </v-tooltip>
      </span>
    </template>

    <RouterFormDialog
      v-model="routerFormDialog"
      :edited-router="editedRouter"
      :is-router-replacement.sync="isRouterReplacement"
      :router-types="routerTypes"
    />

    <div v-if="hasRouter">
      <!-- SERIAL NUMBER -->
      <SummaryElement
        :value="kitSummaryStore.router.serialNumber"
        label="Serial Number"
        is-id
        read-only
        :is-copyable="hasRouter"
      >
        <template #value-text>
          <span
            class="primary--text cursor-pointer"
            @click="routerStore.openDialog(kitSummaryStore.router)"
          >
            {{ kitSummaryStore.router.serialNumber }}
          </span>
        </template>
        <template #append>
          <OpenInNewWindowButton
            :target-link="`${kitSummaryStore.router.rmsUrl}`"
            hint="Open In RMS"
          />
        </template>
      </SummaryElement>

      <!-- ROUTER TYPE -->
      <SummaryElement v-model="routerType" read-only />

      <!-- POWER SCHEDULE -->
      <SummaryElement v-model="powerSchedule" read-only />

      <!-- VPN SERVER -->
      <SummaryElement v-model="vpnServer" read-only />

      <!-- VPN SERVER Port -->
      <SummaryElement v-model="httpPort" read-only>
        <template #append>
          <v-tooltip v-if="hasRouter" bottom content-class="d-inline-block">
            <template #activator="{ on, attrs }">
              <v-btn
                icon
                color="primary"
                x-small
                v-bind="attrs"
                target="_blank"
                class="ml-1 pb-05"
                :href="vpnServerUrl"
                v-on="on"
                @click.stop="() => {}"
              >
                <v-icon small> fa-external-link-alt </v-icon>
              </v-btn>
            </template>
            <span>Open In A New Window </span>
          </v-tooltip>
        </template>
      </SummaryElement>

      <!-- NETBIRD URL -->
      <SummaryElement
        :value="kitSummaryStore.router.netbirdUrl"
        label="Netbird URL"
        read-only
        :is-copyable="hasRouter"
      >
        <template #value-text>
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <ETruncatedDiv :width="200" v-bind="attrs" v-on="on">
                <a target="_blank" :href="kitSummaryStore.router.netbirdUrl">
                  <span>{{ kitSummaryStore.router.netbirdUrl }}</span>
                </a>
              </ETruncatedDiv>
            </template>
            <span>{{ kitSummaryStore.router.netbirdUrl }}</span>
          </v-tooltip>
        </template>
      </SummaryElement>

      <!-- USER ID -->
      <SummaryElement v-model="routerUserId" read-only />

      <!-- PASSWORD -->
      <SummaryElement
        v-model="routerPassword"
        :is-password="hasRouter"
        read-only
        :is-copyable="hasRouter"
      />

      <!-- SIMs -->
      <SummaryElement read-only label="SIMs">
        <SimsColumn
          :item="kitSummaryStore.router"
          @open-sms-history-dialog="openSmsDialog"
        />
      </SummaryElement>
      <SmsHistoryDialog
        v-model="showSmsHistoryDialog"
        :sim="selectedSim?.number"
        :edited-sim="selectedSim"
        :show-header="false"
      />
    </div>

    <div v-else>
      <!-- ADD ROUTER -->
      <div class="subtitle-2">
        <v-btn
          v-if="!hasRouter"
          color="primary"
          small
          plain
          class="mr-1"
          @click="openRouterFormDialog"
        >
          <v-icon class="mr-2">fa-plus </v-icon> Add Router
        </v-btn>
      </div>

      <!-- LINK EXISTING ROUTER -->
      <div v-if="!hasRouter && suggestedRouter" class="subtitle-2">
        <v-btn
          color="primary"
          small
          plain
          class="mr-1"
          @click="showSuggestedRouterDialog = true"
        >
          <v-icon class="mr-2">fa-plus </v-icon> Link existing router
        </v-btn>

        <v-dialog v-model="showSuggestedRouterDialog" width="450">
          <v-card class="suggested-router-dialog">
            <v-card-title> Link existing router </v-card-title>
            <v-card-text class="pb-0">
              This will link
              <strong> Router: {{ suggestedRouter.serialNumber }} </strong>
              to
              <strong> Kit: {{ kitSummaryStore.kit.serial }} </strong>
              <CameraSummaryRouter
                class="pt-4"
                :camera="cameraWithExistingRouter"
                :router="suggestedRouter"
              />
            </v-card-text>
            <v-card-actions class="pa-4">
              <v-spacer />
              <v-btn
                class="px-6"
                depressed
                @click="showSuggestedRouterDialog = false"
              >
                Cancel
              </v-btn>
              <v-btn
                class="px-5"
                color="primary"
                depressed
                :loading="isLinkingRouter"
                @click="linkSuggestedRouter"
              >
                Link
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>
    </div>
  </ECard>
</template>

<script lang="ts">
import SummaryElement from "@/components/cameraTabs/summary/SummaryElement"
import Vue from "vue"
import { AdminApi } from "@evercam/shared/api/adminApi"
import RouterFormDialog from "@/components/kits/RouterFormDialog"
import OpenInNewWindowButton from "@/components/OpenInNewWindowButton"
import {
  PowerSchedules,
  PowerTypes,
  VpnServers,
} from "@/components/constants.js"
import SimsColumn from "@/components/SimsColumn"
import { mapStores } from "pinia"
import { useKitSummaryStore } from "@/stores/kitSummary"
import { useRouterStore } from "@/stores/router"
import SmsHistoryDialog from "@/components/sims/SmsHistoryDialog.vue"
import { AdminCamera, Router, Sim } from "@evercam/shared/types"
import vpnServers from "@evercam/shared/constants/vpnServers"
import CameraSummaryRouter from "@/components/cameraTabs/summary/CameraSummaryRouter.vue"
import { formatUrl } from "@evercam/shared/utils"

export default Vue.extend({
  components: {
    CameraSummaryRouter,
    SmsHistoryDialog,
    SummaryElement,
    RouterFormDialog,
    SimsColumn,
    OpenInNewWindowButton,
  },
  data() {
    return {
      selectedSim: null as Sim | null,
      showSmsHistoryDialog: false,
      routerFormDialog: false,
      isRouterReplacement: false,
      editedRouter: {},
      routerTypes: [],
      cameras: [],
      suggestedRouter: null as Router | null,
      showSuggestedRouterDialog: false,
      isLinkingRouter: false,
      vpnServer: {
        key: "vpnServer",
        label: "VPN Server",
        value: "",
        type: "SINGLE_SELECT",
        dataset: VpnServers,
      },
      httpPort: {
        key: "routerHttpPort",
        label: "HTTP Port",
        value: null,
        type: "TEXT_FIELD",
      },
      powerSchedule: {
        key: "powerSchedule",
        label: "Power Schedule",
        value: "",
        type: "SINGLE_SELECT",
        dataset: PowerSchedules,
      },
      routerType: {
        key: "routerType",
        label: "Router Type",
        value: "",
        type: "SINGLE_SELECT",
        dataset: [],
      },
      routerUserId: {
        key: "routerUserId",
        label: "Router User ID",
        value: "",
        type: "TEXT_FIELD",
      },
      routerPassword: {
        key: "routerPassword",
        label: "Router Password",
        value: "",
        type: "TEXT_FIELD",
      },
      powerType: {
        key: "powerType",
        label: "Power Type",
        value: "",
        type: "SINGLE_SELECT",
        dataset: PowerTypes,
      },
    }
  },
  computed: {
    ...mapStores(useKitSummaryStore, useRouterStore),
    hasRouter(): boolean {
      return !!this.kitSummaryStore.router.id
    },
    cameraWithExistingRouter(): AdminCamera | null {
      return (
        (this.kitSummaryStore.cameras.find(
          (c) => !!c.routerId
        ) as unknown as AdminCamera) || null
      )
    },
    suggestedRouterId(): number | null {
      return this.cameraWithExistingRouter?.routerId || null
    },
    imageUrl(): string {
      let routerModel = ""

      switch (this.kitSummaryStore.router?.routerType) {
        case "RUTX11":
          routerModel = "rutx11"
          break
        case "Teltonika RUTX14":
          routerModel = "rutx14"
          break
        case "Teltonika 955":
          routerModel = "rut955"
          break
        case "Teltonika 956":
          routerModel = "rut956"
          break
        default:
          routerModel = "rut955"
          break
      }

      return require(`~/assets/img/router/${routerModel}.gif`)
    },
    vpnServerUrl(): string {
      const vpnServer = vpnServers.find(
        (el) => el.name === this.vpnServer.value
      )

      return formatUrl({
        port: this.httpPort.value,
        host: vpnServer.value,
      })
    },
  },
  watch: {
    hasRouter: {
      immediate: true,
      deep: true,
      async handler() {
        this.bindRouter()
      },
    },
    suggestedRouterId: {
      immediate: true,
      handler() {
        if (!this.hasRouter && this.suggestedRouterId) {
          this.fetchSuggestedRouter()
        }
      },
    },
  },
  async created() {
    this.bindRouter()
    await this.getRouterTypes()
  },
  methods: {
    bindRouter() {
      this.routerType.value = this.kitSummaryStore.router.routerType
      this.powerType.value = this.kitSummaryStore.router.powerType
      this.powerSchedule.value = this.kitSummaryStore.router.powerSchedule
      this.routerPassword.value = this.kitSummaryStore.router.routerPassword
      this.routerUserId.value = this.kitSummaryStore.router.routerUserId
      this.vpnServer.value = this.kitSummaryStore.router.vpnServer
      this.httpPort.value = this.kitSummaryStore.router.routerHttpPort
    },
    async getRouterTypes() {
      try {
        this.routerTypes = await AdminApi.routers.getRouterTypes()
        let types = []
        this.routerTypes.forEach((element) => {
          types.push({ value: element, name: element })
        })
        this.routerType.dataset = types
      } catch (error) {
        this.$notifications.error({
          text: "Could not load router types!",
          error,
        })
      }
    },
    async fetchSuggestedRouter() {
      try {
        this.suggestedRouter = (await AdminApi.routers.getRouter(
          this.suggestedRouterId
        )) as unknown as Router
      } catch (e) {
        console.log(
          `Failed to fetch suggested router ${this.suggestedRouterId}`,
          e
        )
      }
    },
    async linkSuggestedRouter() {
      try {
        this.isLinkingRouter = true
        await AdminApi.routers.updateRouter(this.suggestedRouterId, {
          kitId: this.kitSummaryStore.kit.id,
        })
        this.kitSummaryStore.router = {
          ...this.suggestedRouter,
        }
        this.$notifications.success(
          `Router ${this.suggestedRouterId} linked to kit ${this.kitSummaryStore.kit.serial} successfully`
        )
      } catch (e) {
        this.$notifications.error({
          text: "Failed to link router to Kit!",
          error: e,
        })
      } finally {
        this.isLinkingRouter = false
        this.showSuggestedRouterDialog = false
      }
    },
    openRouterFormDialog() {
      this.editedRouter = this.kitSummaryStore.router
      this.routerFormDialog = true
    },
    openReplaceRouterFormDialog() {
      this.editedRouter = {}
      this.isRouterReplacement = true
      this.routerFormDialog = true
    },
    openSmsDialog(sim: Sim) {
      this.selectedSim = sim
      this.showSmsHistoryDialog = true
    },
  },
})
</script>

<style>
.box-details {
  min-height: 280px;
}

.text--add-kit-router {
  height: auto !important;
  font-size: 0.8rem;
  padding: 0 !important;
}
.suggested-router-dialog .summary-element__label {
  font-weight: 600 !important;
}
</style>
